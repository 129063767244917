import { configureStore, combineReducers, isRejectedWithValue, MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { shallowEqual, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import authSlice from './slices/auth.slice';
import appSlice from './slices/app.slice';
import testSlice from './slices/test.slice';
import { UserApi } from './apiSlice/user.slices';
import { RentalApi } from './apiSlice/rental.slices';
import { AnimalApi } from './apiSlice/animal.slices';
import { UnitApi } from './apiSlice/unit.slices';
import { ComplaintApi } from './apiSlice/complaint.slices';
import { AuthApi } from './apiSlice/auth.slices';
import { AuthAmplify } from './apiSlice/authAmplify.slice';

// const rootReducer = combineReducers({
//   [UserApi.reducerPath]: UserApi.reducer,
//   testSlice: testSlice.reducer,
//   authSlice: authSlice.reducer,
// });

const appReducer = combineReducers({
  [ComplaintApi.reducerPath]: ComplaintApi.reducer,
  [AuthApi.reducerPath]: AuthApi.reducer,
  [AuthAmplify.reducerPath]: AuthAmplify.reducer,
  [UnitApi.reducerPath]: UnitApi.reducer,
  [RentalApi.reducerPath]: RentalApi.reducer,
  [AnimalApi.reducerPath]: AnimalApi.reducer,
  [UserApi.reducerPath]: UserApi.reducer,
  testSlice: testSlice.reducer,
  authSlice: authSlice.reducer,
  appSlice: appSlice.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const rtkQueryErrorHandler: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action?.payload?.status === 555) {
      // This will work if migrated user is logged in to old app
      localStorage.clear();
      window.location.reload();
    }
  }
  return next(action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      rtkQueryErrorHandler,
      UserApi.middleware,
      RentalApi.middleware,
      AnimalApi.middleware,
      UnitApi.middleware,
      ComplaintApi.middleware,
      AuthApi.middleware,
      AuthAmplify.middleware
    ),
});

export type RootState = ReturnType<typeof rootReducer>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppSelector: TypedUseSelectorHook<RootState> = (cb) => useSelector(cb, shallowEqual);

setupListeners(store.dispatch);
