import { TAnimal } from '../types/types/Animal';

export enum EGender {
  'FEMALE' = 'Female',
  'MALE' = 'Male',
}

export const simpleAnimalConvertor = (data: DataTypesComesFromBack.IndividualAnimalRequestData): TAnimal => ({
  name: data.name,
  type: data.animalType,
  color: data.animalColors,
  breed: data.animalBreed,
  gender: EGender[data.animalGender as keyof typeof EGender],
  age: data.animalAge,
  ageUnit: data.animalAgeUnit,
  weight: data.animalWeightLbs,
  rentalUnitNumber: data.rentalUnitNumber,
  imageUrls: data.animalImageUrls,
  rentalName: data.rentalName,
  petPhotoUrl: data.petPhotoUrl,
  tenantName: data.tenantName,
  applicationType: data.applicationType,
  expirationDate: data.animalRabiesVaccinationExpirationDate,
  vaccinatedDate: data.animalRabiesVaccinationGivenDate,
  houseTrained: data.isAnimalHouseTrained ? 'Yes' : 'No',
});
