import { authSplitApi } from '../helpers/slice.helpers';
import { TAnimal, TAnimalFromDb } from '../../types/types/Animal';
import { simpleAnimalConvertor } from '../../convertors/animal.converter';

const URL = 'animal';

export const AnimalApi = authSplitApi('application', ['Animal']).injectEndpoints({
  endpoints: (build) => ({
    getAnimal: build.query<TAnimal, { animalId: string; maintainerId: string }>({
      query({ animalId, ...params }) {
        return {
          url: `${URL}/${animalId}`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['Animal'],
      transformResponse: (data: TAnimalFromDb) => {
        return simpleAnimalConvertor(data);
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetAnimalQuery } = AnimalApi;
