import styled from 'styled-components';
import Typography from '@mui/material/Typography';

import { theme } from '../../../../components/theme';

const Theme = theme as { [key: string]: any };

export const IndividualItemS = styled.div<{
  justifyContent?: string;
  flexDirection?: string;
  alignItems?: string;
  padding?: string;
}>`
  display: flex;
  border: 1px solid ${Theme.primary};
  border-radius: 8px;
  padding: ${({ padding }) => padding || '16px'};
  margin-top: 12px;
  flex-direction: ${({ flexDirection }) => flexDirection || 'unset'};
  justify-content: ${({ justifyContent }) => justifyContent || 'unset'};
  align-items: ${({ alignItems }) => alignItems || ''};
  width: 100%;
  .info-text {
    font-size: 20px;
    font-weight: 500;
  }
  :first-child {
    margin-top: 16px;
  }
`;

export const AnimalCharacteristics = styled(Typography)`
  &.MuiTypography-root {
    color: ${Theme.secondGray};
    font-size: 16px;
    font-weight: 500;
  }
`;

export const InfoWrapper = styled.div`
  padding: 0 16px 16px;
`;

export const AnimalsS = styled.div`
  margin-top: 20px;
`;

export const InfoField = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '50%'};
`;
