import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { store } from './redux/store';

import { theme } from './components/theme';
import { RoutesData } from './routes/RoutesData';
import { RoutesNames } from './routes/RoutesNames';
import { StoreProvider } from './contexts/store';
import { VersionProvider } from './contexts/Version';
import ErrorBoundary from './ErrorBoundary';
import ErrorSuccessHandling from './components/Base/ErrorSuccessHandling';
import './App.css';

interface Props {
  component: () => JSX.Element | null;
  path: string;
  exact: boolean;
}

function App() {
  const PrivateRoute = ({ component: Component, ...rest }: Props) => {
    const user = JSON.parse(localStorage.getItem('user') as string);
    return <Route {...rest} render={(props: any) => (user ? <Component {...props} /> : <Redirect to='/' />)} />;
  };

  const PublicRoute: FC<Props> = ({ component: Component, ...rest }) => {
    const user = JSON.parse(localStorage.getItem('user') as string);
    return (
      <Route
        {...rest}
        render={(props: any) => (user ? <Redirect to={RoutesNames.animals} /> : <Component {...props} />)}
      />
    );
  };

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <StoreProvider>
          <VersionProvider>
            <>
              <Router>
                <ErrorSuccessHandling>
                  <ThemeProvider theme={theme}>
                    <Switch>
                      {RoutesData.map((route, index) => {
                        const { path, exact, component, isPrivate } = route;
                        return isPrivate ? (
                          <PrivateRoute path={path} exact={exact} component={component} key={index.toString()} />
                        ) : (
                          <PublicRoute path={path} exact={exact} component={component} key={index.toString()} />
                        );
                      })}
                      <Route path='*'>
                        <Redirect to='/' />
                      </Route>
                    </Switch>
                  </ThemeProvider>
                </ErrorSuccessHandling>
              </Router>
            </>
          </VersionProvider>
        </StoreProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
