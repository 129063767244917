import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import MainLayout from '../../components/Base/MainLayout';
import { RoutesNames } from '../../routes/RoutesNames';
import AnimalDetail from './components/AnimalDetail';
import { useAppSelector } from '../../redux/store';
import { useGetAnimalQuery } from '../../redux/apiSlice/animal.slices';

const AnimalView = () => {
  const history = useHistory();
  const { user: profile } = useAppSelector((state) => ({
    user: state.authSlice.user,
  }));

  const { id } = useParams<{ id: string }>();

  const { data: animal, isError } = useGetAnimalQuery(
    { animalId: id, maintainerId: profile?.id || '' },
    {
      skip: !profile?.id,
    }
  );

  useEffect(() => {
    if (!isError) {
      return;
    }
    history.push(RoutesNames.animals);
  }, [isError]);

  return <MainLayout padding='0 20px 68px 20px'>{animal && <AnimalDetail data={animal} />}</MainLayout>;
};

export default AnimalView;
