import { useEffect, useState } from 'react';

import InfiniteHits from 'components/Base/Algolia/components/InfiniteHits';
import { SearchBox } from 'components/Base/Algolia/components/SearchBox';
import AlgoliaProvider from 'components/Base/Algolia';

import MainLayout from 'components/Base/MainLayout';
import AccordionList from 'components/Base/AccordionList';

import { AnimalsContentTypes } from 'types/types';
import { useAppSelector } from 'redux/store';

import { useGetAnimalAlgoliaKey } from 'Hooks/animal';

import Animal from './components/Animal';
import { useGetRefinementList } from './components/useGetRefinementList';

const Animals = (): JSX.Element => {
  const [apiKey, setApiKey] = useState('');
  const maintarinerId = useAppSelector((state) => state.authSlice.user?.id);
  const indexName = process.env.REACT_APP_ALGOLIA_APPLICATION_INDEX;
  const configs = {
    hitsPerPage: 5,
    typoTolerance: false,
    attributesToHighlight: [
      'animalName',
      'rentalName',
      'tenantName',
      'animalType',
      'animalBreed',
      'applicationType',
      'rentalUnitNumber',
    ],
    attributesToSnippet: ['animalColors'],
  };

  const { reFetch } = useGetAnimalAlgoliaKey(maintarinerId, setApiKey);
  const { list: refinementList } = useGetRefinementList();

  useEffect(() => {
    if (!maintarinerId) return;
    reFetch();
  }, [maintarinerId]);

  return (
    <MainLayout padding='0 20px 64px 20px'>
      <AlgoliaProvider
        apiKey={apiKey}
        refreshApiKey={reFetch}
        indexName={indexName!}
        configs={configs}
        refinementListTitle='Animal Filters'
        refinementListTransform={() => <AccordionList list={refinementList} />}
      >
        <>
          <SearchBox placeholder='search' />
          <InfiniteHits<AnimalsContentTypes> hitComponent={(data) => <Animal item={data} />} />
        </>
      </AlgoliaProvider>
    </MainLayout>
  );
};

export default Animals;
