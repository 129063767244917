import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

export const SSearch = styled('div')`
  position: relative;
  width: 100%;
`;

export const SIconButton = styled(IconButton)`
  position: absolute;
  right: 12px;
  top: 5px;
`;

export const STextField = styled(TextField)`
  & input {
    padding-right: 64px;
  }
`;

export const Loading = styled('div')`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  background: black;
  z-index: 1600;
  display: ${({ hidden }) => (!hidden ? `flex` : `none`)};
  align-items: center;
  justify-content: center;
`;
