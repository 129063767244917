import React from 'react';
import { AutoComplete } from '@landlord-tech/opp-ui-kit';
import { FormBlock, FormFields } from '../../styles';
import { MediumLightGrayText } from '../../../style';
import { ReportAnimalComplaintAddressProps } from '../../../../../types/types';
import { useGetRentalNamesQuery } from '../../../../../redux/apiSlice/rental.slices';

const Address = ({ handleChange, value, errors, handleBlur, touched }: ReportAnimalComplaintAddressProps) => {
  const { data: rentalNames } = useGetRentalNamesQuery();

  return (
    <FormBlock>
      <MediumLightGrayText>Address form</MediumLightGrayText>
      <FormFields>
        <AutoComplete
          name='rentalId'
          label='Property name'
          handleChange={(name: string, value: any) => {
            handleChange({
              target: {
                name,
                value: value[0].key,
              },
            });
          }}
          value={value ? value.toString() : ''}
          placeholder='Property name'
          errorMessage={(touched.rentalId && errors.rentalId) as string}
          options={rentalNames || []}
          multiple={false}
          handleBlur={handleBlur}
        />
      </FormFields>
    </FormBlock>
  );
};

export default Address;
