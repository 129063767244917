import React, { useEffect, useState } from 'react';

import { Button, Input } from '@landlord-tech/opp-ui-kit';
import {
  BackToSettings,
  EditBtns,
  PhotoChange,
  PhotoEdit,
  PhotoEditInput,
  ProfileImage,
  SettingsPageName,
} from '../../styles';
import { usePhotoUpload } from '../../../../Hooks/uploadFile';

import { useUpdateProfile } from '../../../../Hooks/user';

import profileIcon from '../../../../assets/images/profileIcon.svg';
import { useAppState, useAppDispatch } from '../../../../contexts/store';

import { UseForm } from '../../../../components/useForm';
import { ArrowTopIcon, PhotoEditIcon } from '../../../../components/Base/Icons/Icons';

import { Upload } from '../../../../shared/file';
import { InputField } from '../../../Login/styles';
import { validationSchema } from './validationSchema';
import { useAppSelector, useAppDispatch as useAppDispatchRedux } from '../../../../redux/store';
import authSlice from '../../../../redux/slices/auth.slice';
import { useUpdateProfileMutation } from '../../../../redux/apiSlice/user.slices';

type PhotoUploadProps = {
  handleLoading: (val: boolean) => void;
  handleEditableInfo: (val: string) => void;
};

const PhotoUpload = ({ handleLoading, handleEditableInfo }: PhotoUploadProps) => {
  const dispatch = useAppDispatch();
  const [updatedPhotoUrl, setUpdatedPhotoUrl] = useState('');
  const dispatchRedux = useAppDispatchRedux();

  const { user: profile } = useAppSelector((state) => ({
    user: state.authSlice.user,
  }));

  const { reFetch } = usePhotoUpload(async (photoUrl) => {
    setUpdatedPhotoUrl(photoUrl);
  });

  const [updateProfile, { isLoading: loading, isSuccess, isError }] = useUpdateProfileMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          severity: 'success',
          message: 'Your Profile was updated successfully',
        },
      });
      handleEditableInfo('');
    }
    if (isError) {
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          severity: 'error',
          message: 'Something went wrong',
        },
      });
    }
  }, [isSuccess, isError]);

  const handleUploadClick = async (event: any) => {
    handleLoading(true);
    const selectedFile = event.target.files[0];
    const formData = Upload({ file: selectedFile });

    await reFetch({ body: formData });
    handleLoading(false);
  };

  const handleSubmit = (values: { name: string }) => {
    if (!profile) {
      return;
    }
    updateProfile({
      ...profile,
      fullName: values.name || profile?.fullName,
      photoUrl: updatedPhotoUrl || profile?.photoUrl,
    });
  };

  const {
    errors,
    touched,
    values,
    handleSubmit: handleFormSubmit,
    handleChange,
    handleBlur,
  } = UseForm({
    initialValues: { name: profile?.fullName },
    onSubmit: (val: { name: string }) => handleSubmit(val),
    validationSchema,
    validateOnChange: true,
  });

  return (
    <PhotoEdit>
      <BackToSettings onClick={() => handleEditableInfo('')}>
        <ArrowTopIcon color='#5F5F5F' />
        <SettingsPageName>Profile settings</SettingsPageName>
      </BackToSettings>
      <form id='update-profile' onSubmit={handleFormSubmit}>
        <ProfileImage>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='contained-button-profile'>
            <img src={updatedPhotoUrl || profile?.photoUrl || profileIcon} alt='' />
          </label>
          <PhotoEditInput>
            <input accept='.jpg,.jpeg,.png' id='contained-button-profile' type='file' onChange={handleUploadClick} />
          </PhotoEditInput>
          <PhotoChange>
            <PhotoEditIcon />
          </PhotoChange>
        </ProfileImage>
        <InputField>
          <Input
            type='text'
            name='name'
            value={values.name}
            placeholder='Name'
            label='Full name'
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={(touched?.name && errors?.name) as string}
          />
        </InputField>
        <EditBtns>
          <Button className='cancel-btn' text='Cancel' onClick={() => handleEditableInfo('')} />
          <Button
            type='submit'
            text='Save'
            form='update-profile'
            disabled={(!updatedPhotoUrl && (!values.name || values.name === profile?.fullName)) || loading}
          />
        </EditBtns>
      </form>
    </PhotoEdit>
  );
};

export default PhotoUpload;
