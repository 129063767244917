import moment from 'moment';

import MultiSelectFilter from 'components/Base/Algolia/components/RefinementList/FiltersType/MultiSelectFilter';
import NumericFilter from 'components/Base/Algolia/components/RefinementList/FiltersType/NumericFilter';
import { TRefinmentItemTransform } from 'components/Base/Algolia/components/RefinementList/types';

import { EFilterKeys } from 'components/Base/Algolia/enum';

export const filterItems: TRefinmentItemTransform[] = [
  {
    header: EFilterKeys.AnimalName,
    attribute: 'animalName',
    details: <MultiSelectFilter attribute='animalName' searchable />,
  },
  {
    header: EFilterKeys.AnimalType,
    attribute: 'animalType',
    details: <MultiSelectFilter attribute='animalType' searchable />,
  },
  {
    header: EFilterKeys.AnimalBreed,
    attribute: 'animalBreed',
    details: <MultiSelectFilter attribute='animalBreed' searchable />,
  },
  {
    header: EFilterKeys.ApplicationType,
    attribute: 'applicationType',
    details: <MultiSelectFilter attribute='applicationType' searchable />,
  },
  {
    header: EFilterKeys.AnimalColors,
    attribute: 'animalColors',
    details: <MultiSelectFilter attribute='animalColors' searchable />,
  },
  {
    header: EFilterKeys.VaccinationExpiration,
    attribute: 'animalRabiesVaccinationExpirationDateEpoch',
    details: (
      <NumericFilter
        attribute='animalRabiesVaccinationExpirationDateEpoch'
        items={[
          { label: 'All' },
          { label: 'Expired', end: Number(moment().subtract(1, 'days').format('x')) },
          {
            label: 'Expiring this month',
            start: Number(moment().format('x')),
            end: Number(moment().endOf('month').format('x')),
          },
          {
            label: 'Expiring next month or later',
            start: Number(moment().add(1, 'month').startOf('month').format('x')),
          },
        ]}
      />
    ),
  },
  {
    header: EFilterKeys.PropertyName,
    attribute: 'rentalName',
    details: <MultiSelectFilter attribute='rentalName' searchable />,
  },
];
