import { authSplitApi } from '../helpers/slice.helpers';
import { TComplaint, TComplaintData, TComplaintForCreate, TComplaintFromDb } from '../../types/types/Complaint';
import { simpleComplaintConverter, simpleComplaintsConverter } from '../../convertors/complaint.converter';

const URL = 'complaint';

export const ComplaintApi = authSplitApi('complaint', ['Complaint', 'Complaints']).injectEndpoints({
  endpoints: (build) => ({
    getComplaint: build.query<TComplaintData, { complaintId: string; userId: string }>({
      query({ complaintId, ...params }) {
        return {
          url: `${URL}/${complaintId}`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['Complaint'],
      transformResponse: (data: TComplaintFromDb) => {
        return simpleComplaintConverter(data);
      },
    }),
    getComplaints: build.query<TComplaint[], { maintainerId: string; unitId: string }>({
      query(params) {
        return {
          url: `${URL}/all`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['Complaints'],
      transformResponse: (data: TComplaintFromDb[]) => {
        return simpleComplaintsConverter(data);
      },
    }),
    createComplaint: build.mutation<void, TComplaintForCreate>({
      query(body) {
        return {
          url: `${URL}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Complaints'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetComplaintQuery, useGetComplaintsQuery, useCreateComplaintMutation } = ComplaintApi;
