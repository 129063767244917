import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@landlord-tech/opp-ui-kit';
import { ReportAnimalComplaintS } from './styles';
import { UseForm } from '../../useForm';
import { validationSchema } from './validationSchema';
import { useAppDispatch } from '../../../contexts/store';
import FormContent from './components/FormContent';
import { EditBtns } from '../../../pages/ProfileEdit/styles';
import { useCreateComplaintMutation } from '../../../redux/apiSlice/complaint.slices';

const ReportAnimalComplaint = ({ handleModalOpen }: { handleModalOpen: (val: boolean) => void }) => {
  const dispatch = useAppDispatch();
  const {
    location: { state },
  } = useHistory() as { location: { state: { propertyId: string; unitNumber: string } } };
  const [imageUrls, setImageUrls] = useState<string[] | []>([]);

  const [createComplaint, { isSuccess, isError }] = useCreateComplaintMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          severity: 'success',
          message: 'Complaint successfully submitted',
        },
      });
      handleModalOpen(false);
    }
    if (isError) {
      dispatch({
        type: 'OPEN_ALERT',
        payload: {
          severity: 'error',
          message: 'Something went wrong',
        },
      });
    }
  }, [isSuccess, isError]);

  const handleSubmit = async (val: {
    rentalId: string;
    unitNumber: string;
    animalComplaintType: string;
    tenantName?: string;
    message?: string;
    unitId: string;
  }) => {
    await createComplaint({
      ...val,
      status: 'Pending',
      unitId: Number(val.unitId),
      ...(imageUrls.length > 0 && { photos: imageUrls }),
    });
  };

  const {
    errors,
    values,
    touched,
    handleBlur,
    setErrors,
    handleSubmit: handleFormSubmit,
    handleChange,
  } = UseForm({
    initialValues: {
      rentalId: state?.propertyId ? state.propertyId : '',
      unitNumber: state?.unitNumber ? state.unitNumber : '',
      animalComplaintType: '',
    },
    onSubmit: (val: {
      rentalId: string;
      unitNumber: string;
      animalComplaintType: string;
      tenantName?: string;
      message?: string;
      unitId: string;
    }) => handleSubmit(val),
    validationSchema,
    validateOnChange: true,
  });

  useEffect(() => {
    if (values.rentalId && errors.rentalId) {
      setErrors({
        ...errors,
        rentalId: '',
      });
    } else if (values.animalComplaintType && errors.animalComplaintType) {
      setErrors({
        ...errors,
        animalComplaintType: '',
      });
    }
  }, [errors, setErrors, values]);

  return (
    <>
      <ReportAnimalComplaintS>
        <form id='save-new-complaint' onSubmit={handleFormSubmit}>
          <FormContent
            errors={errors}
            touched={touched}
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setImageUrls={setImageUrls}
            imageUrls={imageUrls}
          />
        </form>
      </ReportAnimalComplaintS>
      <EditBtns justify='center' className='edit-btn'>
        <Button form='save-new-complaint' className='submit-complaint' type='submit' text='Submit animal complaint' />
      </EditBtns>
    </>
  );
};

export default ReportAnimalComplaint;
