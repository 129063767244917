import { Hit } from 'instantsearch.js';
import { isArray, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { ViewBox } from 'components/Base/Propertie/style';
import IconButton from 'components/Base/Buttons/IconButton';
import { CardImage, InfoTitle } from 'components/Base/style';

import Highlight from 'components/Base/Algolia/components/Highlight';
import Snippet from 'components/Base/Algolia/components/Snippet';

import { AnimalsContentTypes } from 'types/types';
import { RoutesNames } from 'routes/RoutesNames';

import animalIcon from 'assets/images/animalIcon.svg';
import { Flex } from '../../../IndividualAnimal/style';
import { IndividualItemS, InfoField, AnimalCharacteristics, InfoWrapper } from './style';

type TIndividualAnimalProps = { item: Hit<AnimalsContentTypes>; unitAnimal?: boolean };

const IndividualAnimal = ({ item, unitAnimal }: TIndividualAnimalProps): JSX.Element => {
  const history = useHistory();
  const { petPhotoUrl, id, propertyId, rentalUnitNumber, tenantName } = item;

  return (
    <IndividualItemS flexDirection='column' padding='0'>
      <Flex margin='0 0 10px 0' justifyContent='flex-start'>
        <CardImage>
          <img src={petPhotoUrl || animalIcon} alt='' />
        </CardImage>
      </Flex>
      <InfoWrapper>
        <Typography className='info-text' variant='h4'>
          <Highlight hit={item} attribute='animalName' />
        </Typography>
        <Flex margin='0 0 16px 0' justifyContent='flex-start'>
          <InfoField width='100%'>
            <AnimalCharacteristics>
              {(['animalColors', 'animalType', 'animalBreed'] as (keyof AnimalsContentTypes)[]).reduce(
                (acc, attribute, index, raw) => {
                  if (isEmpty(item[attribute])) {
                    return acc;
                  }

                  acc.push(
                    <>
                      {isArray(item[attribute]) ? (
                        <Snippet hit={item} attribute={attribute} />
                      ) : (
                        <Highlight hit={item} attribute={attribute} />
                      )}
                      {index + 1 !== raw.length && ', '}
                    </>
                  );
                  return acc;
                },
                [] as JSX.Element[]
              )}
            </AnimalCharacteristics>
          </InfoField>
        </Flex>
        {!unitAnimal && (
          <Flex margin='0 0 20px 0' justifyContent='flex-start'>
            <InfoField width='100%'>
              <InfoTitle>Property / unit</InfoTitle>
              <Typography className='info-text' variant='h4'>
                <Highlight hit={item} attribute='rentalName' />, <Highlight hit={item} attribute='rentalUnitNumber' />
              </Typography>
            </InfoField>
          </Flex>
        )}
        <Flex margin='0 0 20px 0' justifyContent='flex-start'>
          <InfoField>
            <InfoTitle>Type of application</InfoTitle>
            <Typography className='info-text' variant='h4'>
              <Highlight hit={item} attribute='applicationType' />
            </Typography>
          </InfoField>
          {!!tenantName && (
            <InfoField>
              <InfoTitle>Tenant</InfoTitle>
              <Typography className='info-text' variant='h4'>
                <Highlight hit={item} attribute='tenantName' />
              </Typography>
            </InfoField>
          )}
        </Flex>
        <ViewBox>
          <IconButton
            handleClick={() =>
              history.push({
                pathname: `${RoutesNames.animals}/${id}`,
                state: { unitNumber: rentalUnitNumber, propertyId },
              })
            }
          >
            View
          </IconButton>
        </ViewBox>
      </InfoWrapper>
    </IndividualItemS>
  );
};

export default IndividualAnimal;
